<template>
    <div class="view-profiles">
        <viewWrapper>
            <template #header>
                <textNav @click="$router.push('/informations')">Wróć do dodatkowych informacji</textNav>
            </template>
            <template #content>
                <splitedView>
                    <template #left>
                        <visitSummary />
                    </template>
                    <template #right>
                        <profilesList :items="profiles" @select="selected = $event"/>
                        <div class="profiles-footer">
                            <btn type="next" :disabled="selected ? false : true" @click="selectProfile()">kontynuuj</btn>
                        </div>
                    </template>
                </splitedView>
            </template>
        </viewWrapper>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import viewWrapper from '@/components/layout/viewWrapper/viewWrapper.vue';
import splitedView from '@/components/layout/splitedView/splitedView.vue';
import visitSummary from '@/components/functional/visitSummary/visitSummary.vue';
import profilesList from '@/components/functional/profilesList/profilesList.vue';

export default {

    name: 'Profiles',
    components: {
        viewWrapper,
        splitedView,
        visitSummary,
        profilesList,
    },
    data(){
        return {

            selected: null,

        };
    },
    methods: {

        ...mapActions('user', ['setProfile', 'setLoyaltyDiscount']),

        selectProfile(){
            this.setProfile(this.selected);
            this.manageRoute();
        },

        manageRoute(){
            this.$router.push('/finalization');
        },

    },
    created(){
        this.setProfile(null);
    },
    computed:{
        ...mapState('user', ['profiles']),
    },

};

</script>

<style lang="scss" src="./Profiles.scss" />
