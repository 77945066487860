<template>
    <div class="func-profiles-list">
        <div class="func-profiles-list__header">
            <h2>Wybierz członka rodziny, dla którego chcesz umówić wizytę</h2>
        </div>
        <div class="func-profiles-list__content">
            <div class="scroll-container">
                <div v-for="(profile, index) in items" :key="index"
                class="list-item"
                :class="{'list-item--active': checkSelected(profile.id)}"
                @click="selectItem(profile)">
                    <div class="list-item__image">
                        <img src="~@/assets/icons/user_grey.svg" alt="profile picture"/>
                    </div>
                    <div class="list-item__content">
                        <h3>{{profile.name}} {{profile.surname}}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    
    name: 'profilesList',
    props: {
        
        items: {
            type: Array,
            default: () => [],
        },

    },
    data(){
        return {

            selected: null,

        };
    },
    methods: {

        selectItem(item){

            this.selected = item;
            this.$emit('select', this.selected);

        },

        checkSelected(id){

            return this.selected?.id === id;

        },

    },

};

</script>

<style lang="scss" src="./profilesList.scss" />
